import appLogoProduct from "src/assets/images/Logo.png"
import appLogoDev from "src/assets/images/app-logo-dev.svg"
export const isDevMode = () => {
  return process.env.REACT_APP_ENV === "DEVELOP"
}
export const getLogoApp = () => {
  if (isDevMode()) {
    return appLogoDev
  } else {
    return appLogoProduct
  }
}
